<template>
  <div class="container">
      <swiper class="swiper" :options="swiperOptions" :style="{width: getClientWidth,height: '100%'}" ref="mySwiper">
          <swiper-slide v-for="(img,index) in imgs" :key="index">
            <img class="img" :src="img">
            <div v-show="index > 4 && index < 49 && !noshow.includes(index)" class="back-box" :style="{ height: backBoxHeight}">
              <img class="back" :src="backImg" @click="backTop" :style="{marginBottom: backMarginBottom}">
              <div v-show="index > 0 && index < 49" class="tips">仅供李锦记员工内部参考，切勿对外分享</div>
            </div>
            <div v-show="index == 4" class="menu-box" :style="{top: menuTop, left: menuLeft }">
              <div class="menu-1" :style="{width: menuWidth, height: menuHeight, marginBottom: marginBottom}" @click="goto(1)"></div>
              <div class="menu-2" :style="{width: menuWidth, height: menuHeight, marginBottom: marginBottom}" @click="goto(2)"></div>
              <div class="menu-3" :style="{width: menuWidth, height: menuHeight, marginBottom: marginBottom}" @click="goto(3)"></div>
              <div class="menu-4" :style="{width: menuWidth, height: menuHeight, marginBottom: marginBottom}" @click="goto(4)"></div>
              <div class="menu-5" :style="{width: menuWidth, height: menuHeight, marginBottom: marginBottom}" @click="goto(5)"></div>

              <div class="menu-6" :style="{width: menuWidth, height: menuHeight, marginBottom: marginBottom, marginTop: marginNextMenuBox}" @click="goto(6)"></div>
              <div class="menu-7" :style="{width: menuWidth, height: menuHeight, marginBottom: marginBottom}" @click="goto(7)"></div>
            </div>
          </swiper-slide>
          <!--<div class="swiper-pagination" slot="pagination"></div>-->
      </swiper>
      <div class="arrow animate__animated animate__fadeOutUp animate__infinite animate__slower"><img :src="arrow" class="arrow-img"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

import 'swiper/css/swiper.css'
//import 'swiper/css/pagination'

export default {
  name: 'lkk',
  components: {
      Swiper,
      SwiperSlide
  },
  directives: {
      swiper: directive
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    menuTop() { 
      return this.clientHeight * 0.27506507 + 'px';
    },
    menuLeft() { 
      return this.clientWidth * 0.32 + 'px';
    },
    menuHeight() {
      return this.clientHeight * 0.02998501 + 'px';
    },
    menuWidth() {
      return this.clientWidth * 0.586667 + 'px';
    },
    marginBottom() {
      //return this.clientHeight * 0.01116071 + 'px';
      return this.clientHeight * 0.011994 + 'px';
    },
    marginNextMenuBox() {
      return this.clientHeight * 0.11994 + 'px';
    },
    getClientWidth() { 
      return this.clientWidth + 'px';
    },
    getClientHeight() { 
      return this.clientHeight + 'px';
    },
    marginTop() {
      //return (this.clientHeight - 667) / 667 * 3.27187995 + 'rem';// 0.343328rem  3.27187995
      return (this.clientWidth - 375) / 10 * 0.75 + 'rem';
    },

    backMarginBottom() {
      return this.clientHeight * 0.05247376 + 'px';
    },
    backBoxMarginBottom() {
      return this.clientHeight * 0.00749625 + 'px';
    },
    backBoxHeight() {
      return this.clientHeight * 0.15742129 + 'px';
    },
  },
  data() {
      return {
          swiperOptions: {
              direction: 'vertical',
              speed: 1000,
              touchRatio: 2,
              longSwipesMs: 5000, 
              pagination: {
                  el: '.swiper-pagination',
                  clickable: true,
              }
        },
        clientWidth: 0,
        clientHeight: 0,
        backImg: require('/static/back.png'),
        arrow: require('/static/arrow.png'),
        noshow: [0,1,2],//[0,1,2,3,4,8,13,19,21,34,35,37,47], // 不显示返回按钮及文字
        imgs: [
          require('/static/001.png'),
          require('/static/001-1.jpg'),
          require('/static/001-2.jpg'),
          require('/static/002.png'),
          require('/static/003.png'),
          require('/static/004.png'),
          require('/static/005.png'),
          require('/static/006.png'),
          require('/static/007.png'),
          require('/static/008.png'),
          require('/static/009.png'),
          require('/static/010.png'),
          require('/static/011.png'),
          require('/static/012.png'),
          require('/static/013.png'),
          require('/static/014.png'),
          require('/static/015.png'),
          require('/static/016.png'),
          require('/static/017.png'),
          require('/static/018.png'),
          require('/static/019.png'),
          require('/static/020.png'),
          require('/static/021.png'),
          /*require('/static/022.png'),
          require('/static/023.png'),
          require('/static/024.png'),
          require('/static/025.png'),
          require('/static/026.png'),
          require('/static/027.png'),
          require('/static/028.png'),
          require('/static/029.png'),
          require('/static/030.png'),
          require('/static/031.png'),
          require('/static/032.png'),
          require('/static/033.jpg'),
          require('/static/034.png'),*/
          require('/static/035.png'),
          require('/static/036.png'),
          require('/static/037.png'),
          require('/static/038.png'),
          require('/static/039.png'),
          require('/static/040.png'),
          require('/static/041.png'),
          require('/static/042.png'),
          require('/static/043.png'),
          require('/static/044.png'),
          require('/static/045.png'),
          require('/static/046.png'),
          require('/static/047.png'),
          require('/static/048.png'),

        ],
      }
  },
  created() { 
    this.clientWidth = document.body.clientWidth; //window.screen.width;//
    this.clientHeight = document.documentElement.clientHeight; //document.body.clientHeight; //window.screen.height;//

    console.log('clientWidth:', this.clientWidth, 'clientHeight:', this.clientHeight, 'innerheight:', window.innerHeight)
  },
  methods: {
    navigateTo(num) {
      this.swiper.slideTo(num, 100, false);
    },
    backTop() { 
      this.navigateTo(4);  // 2
    },
    goto(index) { 
      switch (index) { 
        case 1:
          this.navigateTo(6);  // 3
          break;
        case 2:
          this.navigateTo(10);  // 8
          break;
        case 3:
          this.navigateTo(15);  // 13
          break;
        case 4:
          this.navigateTo(21);  // 19
          break;
        case 5:
          //this.navigateTo(21);
          break;
        case 6:
          //this.navigateTo(35);
          this.navigateTo(24);  // 22
          break;
        case 7:
          //this.navigateTo(37);
          this.navigateTo(26);  // 24
          break;
      }
    },
  },
}
</script>
<style>
.container {
  width: 100%;
  height: 100%;
}
.img {
  width: 100%;
  height: 100%;
}
.swiper-container {
  position: absolute;
}
.swiper{
  --swiper-pagination-color: #fff;/* 两种都可以 */
}
.back-box {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  /*height: 105px;
  margin-bottom: 5px;*/
}
.back {
  width: 100px;
  height: 26px;
  /*margin-bottom: 40px;*/
}
.tips {
  font-size: 12px;
  color: #959595;
  font-weight: bold;
}

.menu-box{
  position: absolute;
  /*top: 180px;
  left: 120px;*/
  
}
.menu-1, .menu-2, .menu-3, .menu-4, .menu-5 {
  /*width: 220px;
  height: 20px;
  margin-bottom: 8px;
  border: 1px solid #fff;*/
  
}

.menu-6 {
  /*margin-top: 80px;*/
}
.menu-6, .menu-7 {
  /*width: 220px;
  height: 20px;
  margin-bottom: 8px;
  
  border: 1px solid #fff;*/
  
}

.arrow {
  width: 100%;
  bottom: 0px;
  height: 30px;
  text-align: center;
  position: absolute;
  z-index: 999;
}
.arrow-img {
  width: 30px;
}
</style>
